import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Strong, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"team"} />
		<Helmet>
			<title>
				STARGAZE EVENTS
			</title>
			<meta name={"description"} content={"Ми створюємо незабутні події, які залишають яскраві враження та дарують радість. Довірте нам свої ідеї, і ми перетворимо їх на реальність."} />
			<meta property={"og:title"} content={"STARGAZE EVENTS"} />
			<meta property={"og:description"} content={"Ми створюємо незабутні події, які залишають яскраві враження та дарують радість. Довірте нам свої ідеї, і ми перетворимо їх на реальність."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/website-example-quarkly.png?v=2020-11-05T19:48:01.806Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65801ab5867c86001f194a8a/images/17_85293.png?v=2023-12-18T10:15:37.785Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65801ab5867c86001f194a8a/images/17_85293.png?v=2023-12-18T10:15:37.785Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65801ab5867c86001f194a8a/images/17_85293.png?v=2023-12-18T10:15:37.785Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65801ab5867c86001f194a8a/images/17_85293.png?v=2023-12-18T10:15:37.785Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65801ab5867c86001f194a8a/images/17_85293.png?v=2023-12-18T10:15:37.785Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65801ab5867c86001f194a8a/images/17_85293.png?v=2023-12-18T10:15:37.785Z"} />
		</Helmet>
		<Components.HeaderNew>
			<Override slot="text" />
			<Override slot="quarklycommunityKitMobileSidePanel" />
			<Override slot="text1" />
		</Components.HeaderNew>
		<Section padding="80px 0 80px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				margin="0px 0px 64px 0px"
				width="100%"
				sm-margin="0px 0px 30px 0px"
				padding="0px 200px 0px 200px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 1rem 0px"
					color="--dark"
					text-align="center"
					font="--headline1"
					opacity="1"
					width="100% border-box"
					xl-text-transform="initial"
				>
					Послуги
				</Text>
			</Box>
			<Box
				display="grid"
				flex-wrap="wrap"
				width="100%"
				align-items="center"
				justify-content="center"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="30px"
				md-grid-template-columns="1fr"
				margin="0px 0px .7rem 0px"
			>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
				>
					<Image
						src="https://uploads.quarkly.io/667472d28e2e8e00217d2c55/images/2-1.jpg?v=2024-06-20T19:52:03.664Z"
						border-radius="16px"
						margin="0px 0px 32px 0px"
						max-width="100%"
						md-width="100%"
						width="100%"
						height="278px"
						object-fit="cover"
						srcSet="https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/2-1.jpg?v=2024-06-20T19%3A52%3A03.664Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/2-1.jpg?v=2024-06-20T19%3A52%3A03.664Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/2-1.jpg?v=2024-06-20T19%3A52%3A03.664Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/2-1.jpg?v=2024-06-20T19%3A52%3A03.664Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/2-1.jpg?v=2024-06-20T19%3A52%3A03.664Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/2-1.jpg?v=2024-06-20T19%3A52%3A03.664Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/2-1.jpg?v=2024-06-20T19%3A52%3A03.664Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
							<Strong>
								Організація корпоративних заходів
							</Strong>
							{"\n"}
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
							Ми пропонуємо повний спектр послуг з організації корпоративних заходів, включаючи конференції, семінари, тренінги, корпоративні вечірки та тімбілдінги. Наша команда забезпечує комплексний підхід, враховуючи всі аспекти від вибору локації до технічного забезпечення та координації.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
					position="relative"
				>
					<Image
						src="https://images.unsplash.com/photo-1494955870715-979ca4f13bf0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						border-radius="16px"
						margin="0px 0px 32px 0px"
						max-width="100%"
						md-width="100%"
						max-height="278px"
						object-fit="cover"
						width="100%"
						srcSet="https://images.unsplash.com/photo-1494955870715-979ca4f13bf0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1494955870715-979ca4f13bf0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1494955870715-979ca4f13bf0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1494955870715-979ca4f13bf0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1494955870715-979ca4f13bf0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1494955870715-979ca4f13bf0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1494955870715-979ca4f13bf0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
							<Strong>
								Організація весіль
							</Strong>
							{"\n"}
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
							Ми створюємо весілля вашої мрії, враховуючи всі ваші побажання та вимоги. Від планування концепції до втілення найдрібніших деталей – ми дбаємо про те, щоб ваш день був ідеальним. Наша команда забезпечить незабутню атмосферу та враження, які залишаться з вами на все життя.
						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				display="grid"
				flex-wrap="wrap"
				width="100%"
				align-items="center"
				justify-content="center"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="30px"
				md-grid-template-columns="1fr"
			>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
				>
					<Image
						src="https://uploads.quarkly.io/667472d28e2e8e00217d2c55/images/event-manager-showing-laptop-young-woman_23-2148085287.jpg?v=2024-06-20T19:52:03.650Z"
						border-radius="16px"
						margin="0px 0px 32px 0px"
						max-width="100%"
						md-width="100%"
						width="100%"
						height="278px"
						object-fit="cover"
						srcSet="https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/event-manager-showing-laptop-young-woman_23-2148085287.jpg?v=2024-06-20T19%3A52%3A03.650Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/event-manager-showing-laptop-young-woman_23-2148085287.jpg?v=2024-06-20T19%3A52%3A03.650Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/event-manager-showing-laptop-young-woman_23-2148085287.jpg?v=2024-06-20T19%3A52%3A03.650Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/event-manager-showing-laptop-young-woman_23-2148085287.jpg?v=2024-06-20T19%3A52%3A03.650Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/event-manager-showing-laptop-young-woman_23-2148085287.jpg?v=2024-06-20T19%3A52%3A03.650Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/event-manager-showing-laptop-young-woman_23-2148085287.jpg?v=2024-06-20T19%3A52%3A03.650Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/event-manager-showing-laptop-young-woman_23-2148085287.jpg?v=2024-06-20T19%3A52%3A03.650Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
							<Strong>
								Організація приватних вечірок
							</Strong>
							{"\n"}
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
							Вечірка, яка запам’ятається надовго – це наша спеціалізація. Ми організовуємо приватні заходи будь-якого масштабу, враховуючи ваші побажання та потреби. Від тематичних вечірок до VIP-подій – ми забезпечимо високий рівень обслуговування та незабутні враження.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
				>
					<Image
						src="https://uploads.quarkly.io/667472d28e2e8e00217d2c55/images/Events4.jpg?v=2024-06-20T19:52:03.667Z"
						border-radius="16px"
						margin="0px 0px 32px 0px"
						max-width="100%"
						md-width="100%"
						width="100%"
						height="278px"
						object-fit="cover"
						srcSet="https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/Events4.jpg?v=2024-06-20T19%3A52%3A03.667Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/Events4.jpg?v=2024-06-20T19%3A52%3A03.667Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/Events4.jpg?v=2024-06-20T19%3A52%3A03.667Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/Events4.jpg?v=2024-06-20T19%3A52%3A03.667Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/Events4.jpg?v=2024-06-20T19%3A52%3A03.667Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/Events4.jpg?v=2024-06-20T19%3A52%3A03.667Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/Events4.jpg?v=2024-06-20T19%3A52%3A03.667Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
							<Strong>
								Технічне забезпечення заходів
							</Strong>
							{"\n"}
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
							Ми забезпечуємо повний спектр технічних послуг для проведення заходів, включаючи звукове та світлове обладнання, сцену, екрани, проекцію та багато іншого. Наші технічні спеціалісти гарантують бездоганну роботу обладнання та створення необхідної атмосфери на заході.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
				>
					<Image
						src="https://uploads.quarkly.io/667472d28e2e8e00217d2c55/images/start-event-planning-service.webp?v=2024-06-20T19:52:03.653Z"
						border-radius="16px"
						margin="0px 0px 32px 0px"
						max-width="100%"
						md-width="100%"
						width="100%"
						height="278px"
						object-fit="cover"
						srcSet="https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/start-event-planning-service.webp?v=2024-06-20T19%3A52%3A03.653Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/start-event-planning-service.webp?v=2024-06-20T19%3A52%3A03.653Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/start-event-planning-service.webp?v=2024-06-20T19%3A52%3A03.653Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/start-event-planning-service.webp?v=2024-06-20T19%3A52%3A03.653Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/start-event-planning-service.webp?v=2024-06-20T19%3A52%3A03.653Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/start-event-planning-service.webp?v=2024-06-20T19%3A52%3A03.653Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/start-event-planning-service.webp?v=2024-06-20T19%3A52%3A03.653Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
							<Strong>
								Декорування та флористика
							</Strong>
							{"\n"}
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
							Кожен захід потребує унікального декору та оформлення. Ми пропонуємо послуги з декорування та флористики, щоб створити неповторну атмосферу. Наші дизайнери врахують всі ваші побажання та забезпечать стильне та елегантне оформлення вашого заходу.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
					position="relative"
				>
					<Image
						src="https://images.unsplash.com/photo-1504196606672-aef5c9cefc92?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						border-radius="16px"
						margin="0px 0px 32px 0px"
						max-width="100%"
						md-width="100%"
						max-height="278px"
						object-fit="cover"
						width="100%"
						srcSet="https://images.unsplash.com/photo-1504196606672-aef5c9cefc92?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1504196606672-aef5c9cefc92?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1504196606672-aef5c9cefc92?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1504196606672-aef5c9cefc92?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1504196606672-aef5c9cefc92?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1504196606672-aef5c9cefc92?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1504196606672-aef5c9cefc92?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
							<Strong>
								Організація дитячих заходів
							</Strong>
							{"\n"}
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
							Ми знаємо, як зробити дитяче свято яскравим та веселим. Наша команда організовує дні народження, дитячі вечірки, шоу-програми та інші заходи для наймолодших. Креативний підхід та увага до деталей дозволяють створювати справжню казку для ваших дітей.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.NewFooter1>
			<Override slot="link1">
				0969991469
			</Override>
		</Components.NewFooter1>
	</Theme>;
});